import "./card.css";

function Card(props) {
  const { title, iconClass, backImage } = props;
  return (
    <>
      <div className="card-container">
        <div className="card-header">{title}</div>
        <div
          className="card-body"
          style={{ backgroundImage: "URL(" + backImage + ")" }}
        >
          <div className="card-body-cover"></div>
          <i className={iconClass}></i>
          <span className="card-text">This is a test</span>
        </div>
      </div>
    </>
  );
}

export default Card;
