// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getRemoteConfig } from "@firebase/remote-config";
//const { initializeApp, firestore } = require("firebase-admin");
//const firebase = require("firebase-admin");

//import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
//var serviceAccount = require("./firebase-json/test-680c4-cf5c14bff0c6.json");
//import { serviceAccount } from "./firebase-json/test-680c4-cf5c14bff0c6.json";
const firebaseConfig = {
  apiKey: "AIzaSyAOEHfdDP1k2XzoeNI7VuuDiJIFfKXbY5c",
  authDomain: "test-680c4.firebaseapp.com",
  projectId: "test-680c4",
  storageBucket: "test-680c4.appspot.com",
  messagingSenderId: "539010106447",
  appId: "1:539010106447:web:38fc51219dc843667fd63a",
  measurementId: "G-NGPGZ7S6VW",
  //credential: firebase.credential.cert(serviceAccount),
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
export const rm = getRemoteConfig(app);
export const db = getFirestore(app);

//const user = db.collection("Users");
//const rm = getRemoteConfig(app);

//const analytics = getAnalytics(app);

//module.exports = rm;
