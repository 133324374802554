import "./appointment.css";
function Appointment() {
  return (
    <>
      <div id={"container"} className="appointment-container">
        <div id="body_header">
          <h1>Appointment Request Form</h1>
          <p>Make your appointments easier</p>
        </div>
        <form action={"index.html"} method={"post"}>
          <fieldset>
            <legend>
              <span class="number">1</span>Your basic details
            </legend>
            <label for="name">Name*:</label>
            <input
              type="text"
              id="name"
              name="user_name"
              placeholder="Name"
              required
              pattern="[a-zA-Z0-9]+"
            />

            <label for="mail">Email*:</label>
            <input
              type="email"
              id="mail"
              name="user_email"
              placeholder="Email"
              required
            />

            <label for="tel">Phone:</label>
            <input
              type="tel"
              id="tel"
              placeholder="###-###-####"
              name="user_num"
            />

            <label for="skype_name" style={{ display: "none" }}>
              Skype username:
            </label>
            <input
              type="text"
              id="skype_name"
              name="skype_name"
              placeholder="@atchyutn"
              pattern="[a-zA-Z0-9]+"
              style={{ display: "none" }}
            />
          </fieldset>

          <fieldset>
            <legend>
              <span class="number">2</span>Appointment Details
            </legend>
            <label for="appointment_for">Appointment for*:</label>
            <select id="appointment_for" name="appointment_for" required>
              <option value="brakes">Brakes</option>
              <option value="Oil">Oil Change</option>
              <option value="tune">Tune-up</option>
              <option value="other">Other</option>
            </select>
            <label for="appointment_description">
              Appointment Description:
            </label>
            <textarea
              id="appointment_description"
              name="appointment_description"
              placeholder="..."
            ></textarea>
            <label for="date">Date*:</label>
            <input type="date" name="date" value="" required></input>
            <br />
          </fieldset>
          <button type="submit">Request For Appointment</button>
        </form>
      </div>
    </>
  );
}

export default Appointment;
