import { useEffect, useState } from "react";
import "./home.css";
import "@jitsi/react-sdk";
import { JitsiMeeting } from "@jitsi/react-sdk";

//let jitsi = require("https://meet.jit.si/external_api.js");

//const sgMail = require("@sendgrid/mail");
//import {sgMail} from "@sendgrid/mail";
function Home(props) {
  /*
  sgMail.setApiKey(
    "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"
  );
  */
  const { HideAll } = props;
  const [smallScreen, setSmallScreen] = useState(false);
  const [videoButton, setVideoButton] = useState("");
  const [iframe, setIframe] = useState(
    <div className="video-call-btn-container" style={{ display: "none" }}>
      <button
        onClick={() => {
          videoRequest();
        }}
      >
        Video Call
      </button>
    </div>
  );
  var isSmallScreen = false;
  const [videoID, setVideoID] = useState("");

  const _createVideo = () => {
    var ring_timer;
    var jitsi_cover = document.createElement("div");

    setIframe(
      <JitsiMeeting
        domain={"meet.jit.si"}
        roomName={sessionStorage.getItem("videoID")}
        configOverwrite={{
          prejoinPageEnabled: false,
          hideDisplayName: true,
          hideConferenceSubject: true,
          disableDeepLinking: true,
        }}
        userInfo={{
          displayName: "Guest",
        }}
        onApiReady={(externalApi) => {
          externalApi.addListener("videoConferenceLeft", (id) => {
            setIframe(
              <div
                className="video-call-btn-container"
                style={{ display: "none" }}
              >
                <button
                  onClick={() => {
                    videoRequest();
                  }}
                >
                  Video Call
                </button>
              </div>
            );
            setVideoButton("");
          });
          externalApi.addListener("participantJoined", (id) => {
            clearTimeout(ring_timer);
            document
              .querySelector("div[jitsi-parent]")
              .removeChild(jitsi_cover);
          });
        }}
        getIFrameRef={(iframeRef) => {
          iframeRef.style.height = "100vh";
          iframeRef.style.position = "relative";
          iframeRef.setAttribute("jitsi-parent", "");
          iframeRef.scrollIntoView();
          jitsi_cover.className = "jitsi-cover";
          var jitsi_text = document.createElement("span");
          jitsi_text.innerHTML = "Waiting for mechanic to answer, please wait";
          jitsi_cover.appendChild(jitsi_text);
          iframeRef.appendChild(jitsi_cover);

          var ringcount = 60;
          var countDown = setInterval(() => {
            if (ringcount <= 0) {
              clearInterval(countDown);
            }
            jitsi_text.innerHTML =
              "Waiting for mechanic to answer, please wait <b>" +
              ringcount +
              "</b>";
            ringcount--;
          }, 1000);

          ring_timer = setTimeout(() => {
            setIframe(
              <div
                className="video-call-btn-container"
                style={{ display: "none" }}
              >
                <button
                  onClick={() => {
                    videoRequest();
                  }}
                >
                  Video Call
                </button>
              </div>
            );
            setVideoButton("");
          }, 60000);
        }}
      />
    );
  };

  const videoRequest = () => {
    setVideoButton("hidden");
    var url = "https://meet.jit.si/" + sessionStorage.getItem("videoID");
    //setIframe(<iframe className="video-call" allow="camera; microphone; fullscreen; display-capture; autoplay" src={url}></iframe>);
    setIframe(<div id="meet" className="video-call"></div>);
    //console.log(url);
    var xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function () {
      if (this.readyState == 4 && this.status == 200) {
        //call completed
        //setIframe(<div id="meet" className="video-call"></div>);
      }
    };

    xhttp.open(
      "GET",
      "https://jrdispatch.com/email/?code=" + sessionStorage.getItem("videoID"),
      true
    );
    xhttp.send();
  };

  useEffect(() => {
    if (iframe.type == "div" && iframe.props.id == "meet") {
      _createVideo();
    }
  }, [iframe]);

  useEffect(() => {
    if (
      sessionStorage.getItem("videoID") === null ||
      sessionStorage.getItem("videoID") === ""
    ) {
      var _videoID = makeid(30);
      console.log(_videoID);
      sessionStorage.setItem("videoID", _videoID);
      setVideoID(_videoID);
    } else {
      setVideoID(sessionStorage.getItem("videoID"));
    }

    var optionContainer = document.getElementsByClassName(
      "home-header_footer-body_option"
    )[0];
    var optionHeader = document.getElementsByClassName(
      "home-header_footer-option"
    )[0];
    if (optionHeader.offsetWidth < 229) {
      document
        .getElementsByClassName("ad-container")[0]
        .classList.add("full-width");
      document.getElementsByClassName("home-header_footer")[0].style.cursor =
        "pointer";
      setSmallScreen(true);
      isSmallScreen = true;
      let copySelected = { ...selected };
      copySelected.appointment = true;
      setSeleted((selected) => ({ ...copySelected }));
      //optionContainer.style.display = "none";
      for (
        let index = 0;
        index < optionContainer.parentElement.children.length;
        index++
      ) {
        //optionContainer.parentElement.children[index].style.display = "none";
      } //end for
    }
    if (optionHeader.offsetWidth > 228) {
      document
        .getElementsByClassName("ad-container")[0]
        .classList.remove("full-width");
      document.getElementsByClassName("home-header_footer")[0].style.cursor =
        "default";
      setSmallScreen(false);
      isSmallScreen = false;
      let copySelected = { ...selected };
      Object.keys(copySelected).forEach((key) => {
        copySelected[key] = false;
      });
      setSeleted((selected) => ({ ...copySelected }));
      //optionContainer.style.display = "flex";
      for (
        let index = 0;
        index < optionContainer.parentElement.children.length;
        index++
      ) {
        //optionContainer.parentElement.children[index].style.display = "flex";
      } //end for
    }

    verifyOptionSize();
    window.onresize = () => {
      verifyOptionSize();
    };
  }, []);

  const verifyOptionSize = () => {
    var optionContainer = document.getElementsByClassName(
      "home-header_footer-body_option"
    )[0];

    var optionHeader = document.getElementsByClassName(
      "home-header_footer-option"
    )[0];

    //console.log("SmallScreen: ", smallScreen);

    if (optionHeader.offsetWidth < 229 && !isSmallScreen) {
      document
        .getElementsByClassName("ad-container")[0]
        .classList.add("full-width");
      document.getElementsByClassName("home-header_footer")[0].style.cursor =
        "pointer";
      setSmallScreen(true);
      isSmallScreen = true;
      let copySelected = { ...selected };
      copySelected.appointment = true;
      setSeleted((selected) => ({ ...copySelected }));
      //optionContainer.style.display = "none";
      for (
        let index = 0;
        index < optionContainer.parentElement.children.length;
        index++
      ) {
        //optionContainer.parentElement.children[index].style.display = "none";
      } //end for
    } //end if
    if (optionHeader.offsetWidth > 228 && isSmallScreen) {
      document
        .getElementsByClassName("ad-container")[0]
        .classList.remove("full-width");
      document.getElementsByClassName("home-header_footer")[0].style.cursor =
        "default";
      setSmallScreen(false);
      isSmallScreen = false;
      let copySelected = { ...selected };
      Object.keys(copySelected).forEach((key) => {
        copySelected[key] = false;
      });
      setSeleted((selected) => ({ ...copySelected }));
      //optionContainer.style.display = "flex";
      for (
        let index = 0;
        index < optionContainer.parentElement.children.length;
        index++
      ) {
        //optionContainer.parentElement.children[index].style.display = "flex";
      } //end for
    } //end if
  };

  const [option, setOption] = useState(null);
  const [selected, setSeleted] = useState({
    appointment: false,
    open: false,
    location: false,
    call: false,
  });
  const handleOptionClick = (e) => {
    if (smallScreen) {
      var option = e.currentTarget.getAttribute("data-name");
      let copySelected = { ...selected };
      Object.keys(copySelected).forEach((key) => {
        if (option == key) {
          copySelected[key] = true;
        } else {
          copySelected[key] = false;
        }
      });
      setSeleted((selected) => ({ ...copySelected }));
    } //end if smallscreen
  };

  //--------------------video conference logic--------------------
  function makeid(length) {
    var result = "";
    var characters =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const createVideo = () => {
    //var room = makeid(30);
    //var url = "https://meet.jit.si/" + room;
    //sendEmail(url);

    return (
      <iframe
        className="video-call"
        allow="camera; microphone; fullscreen; display-capture; autoplay"
        src={""}
      ></iframe>
    );
  };

  //--------------------video conference logic--------------------
  return (
    <>
      <div className="home-container" style={{ display: HideAll }}>
        <div className="home-header">
          <div className="home-header_body-container">
            <div className="ad-container">
              <h1>Brakes Pads and Labor</h1>
              <div className="special-price">
                <span className="small">from:</span>
                <span className="price-orange">$79.95</span>
                <i className="fa-solid fa-tag"></i>
              </div>
              <div className="ad-button">
                <span>Contact Us</span>
                <i className="fa-solid fa-arrow-right"></i>
              </div>
            </div>
          </div>
          <div className="home-header_footer-container">
            <div className="home-header_footer">
              <div
                className={
                  "home-header_footer-option " +
                  (selected.appointment ? "selected-option" : "")
                }
                onClick={(e) => handleOptionClick(e)}
                data-name={"appointment"}
              >
                <span>Free Appointment</span>
                <div className="home-header_footer-icon-container">
                  <i className="fa-regular fa-calendar-check"></i>
                </div>
              </div>
              <div
                className={
                  "home-header_footer-option " +
                  (selected.open ? "selected-option" : "")
                }
                onClick={(e) => handleOptionClick(e)}
                data-name={"open"}
              >
                <span>Open Hours</span>
                <div className="home-header_footer-icon-container">
                  <i className="fa-regular fa-clock"></i>
                </div>
              </div>
              <div
                className={
                  "home-header_footer-option " +
                  (selected.location ? "selected-option" : "")
                }
                onClick={(e) => handleOptionClick(e)}
                data-name={"location"}
              >
                <span>Location</span>
                <div className="home-header_footer-icon-container">
                  <i className="fa-solid fa-map-location-dot"></i>
                </div>
              </div>
              <div
                className={
                  "home-header_footer-option " +
                  (selected.call ? "selected-option" : "")
                }
                onClick={(e) => handleOptionClick(e)}
                data-name={"call"}
              >
                <span>Call Us</span>
                <div className="home-header_footer-icon-container">
                  <i className="fa-solid fa-phone"></i>
                </div>
              </div>
            </div>
            <div className="home-header_footer-body">
              <div
                className="home-header_footer-body_option"
                style={
                  selected.appointment
                    ? { display: "flex" }
                    : !smallScreen
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                <div className="flex-left">
                  <span>1. Free Diagnosis & Brake Checks</span>
                  <span>2. Message with photo and video</span>
                  <span>3. Call from us</span>
                </div>
                <div
                  className="btnReadMore-container"
                  onClick={() => {
                    window.location = "/appointment";
                  }}
                >
                  <i className="fa-solid fa-arrow-right"></i>
                  <div className="btnReadMore">Schedule a service</div>
                </div>
              </div>
              <div
                className="home-header_footer-body_option open-hours"
                style={
                  selected.open
                    ? { display: "flex" }
                    : !smallScreen
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                <div>
                  <span>Week Days:</span>
                  <span>8am - 5pm</span>
                </div>
                <div>
                  <span>Weekends:</span>
                  <span>8am - 2pm</span>
                </div>
                <div>
                  <span>Sunday:</span>
                  <span>Closed</span>
                </div>
                <div>
                  <span>Emergency:</span>
                  <span>24hr/7days</span>
                </div>
              </div>
              <div
                className="home-header_footer-body_option location"
                style={
                  selected.location
                    ? { display: "flex" }
                    : !smallScreen
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                <div>
                  <div
                    className="btnReadMore-container"
                    onClick={() => {
                      window.open(
                        "https://www.google.com/maps/place/18%C2%B017'51.2%22N+66%C2%B003'24.7%22W/@18.2973351,-66.056873,18.63z/data=!4m5!3m4!1s0x0:0xc7712329282184a9!8m2!3d18.297549!4d-66.0568613",
                        "_blank"
                      );
                    }}
                  >
                    <i className="fa-solid fa-arrow-right"></i>
                    <div className="btnReadMore">Open Maps</div>
                  </div>
                </div>
              </div>
              <div
                className="home-header_footer-body_option call-us"
                style={
                  selected.call
                    ? { display: "flex" }
                    : !smallScreen
                    ? { display: "flex" }
                    : { display: "none" }
                }
              >
                <div className="phone">
                  <span>787-910-2737</span>
                </div>
                <div
                  className="whatsapp"
                  onClick={() => {
                    window.open("https://wa.me/7879102737", "_blank");
                  }}
                >
                  <i className="fa-brands fa-whatsapp"></i>
                  <span>Message Me</span>
                </div>
                <div
                  className="email"
                  onClick={() => {
                    window.open(
                      "mailto:fastlane.automotive.pr@gmail.com?subject=Vehicle Enquiry"
                    );
                  }}
                >
                  <i className="fa-regular fa-envelope"></i>
                  <span>Email Me</span>
                </div>

                <div
                  className={"video " + videoButton}
                  onClick={() => {
                    videoRequest();
                  }}
                >
                  <i className="fa-solid fa-video"></i>
                  <span>Video Call</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home-body">
          {iframe}
          <div className="home-body-pic-container">
            <div className="image">
              <div className="details">
                <img src={require("../images/mechanic.svg").default} />
                <ul>
                  <li>Brakes</li>
                  <li>Oil</li>
                  <li>Mecanica Liviana</li>
                  <li>Diagnostics</li>
                </ul>
              </div>
            </div>
            <div>
              <video
                playsInline
                src={require("../images/home.mp4")}
                autoPlay
                loop
                muted
              />
              <div className="video-cover">
                <div>Porque <span>Nosotros</span>?</div>
                <div></div>
                <div>
                  <ul>
                    <li>Mas de 20 años de experiencia</li>
                    <li>Cero compromisos</li>
                    <li>Diagnostico gratis</li>
                    <li>100% garantía de satisfacción</li>
                  </ul>
                </div>
                <div></div>
                <img
                  src="/images/fastlane-logo-text.svg"
                  style={{
                    position: "absolute",
                    bottom: 0,
                    right: 0,
                    width: "200px",
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
