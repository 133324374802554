import React, { useRef, useEffect, useState } from "react";
import { Routes, Route, Link } from "react-router-dom";
import logo from "./logo.svg";
import "./App.css";

import About from "./components/about";
import Header from "./components/header";
import Footer from "./components/footer";
import Home from "./components/home";
import Appointment from "./components/appointment";

import { collection, getDocs } from "@firebase/firestore";
import {
  getValue,
  getAll,
  getString,
  fetchAndActivate,
  fetchConfig,
} from "@firebase/remote-config";
import { db, rm } from "./api/config";
rm.settings.minimumFetchIntervalMillis = 60000;
function App() {
  const collectionRef = collection(db, "Users");
  const [name, setName] = useState("");
  const [welcome_msg, setWelcomeMsg] = useState("");

  useEffect(() => {
    //----------------Firebase------------------------
    const start = async () => {
      const data = await getDocs(collectionRef);
      let list = data.docs
        .filter((doc) => {
          if (doc.data().age == "34") {
            return doc.data();
          }
        })
        .map((doc) => ({ id: doc.id, ...doc.data() }));

      setName(list[0].name);

      rm.defaultConfig = {
        welcome: "Hello World",
      };
      fetchAndActivate(rm)
        .then(() => {
          // ...
          setWelcomeMsg(getString(rm, "welcome"));
        })
        .catch((err) => {
          // ...
        });
    };
    start();
    //----------------Firebase------------------------
  }, []);

  const [hideall, setHideAll] = useState("flex");
  useEffect(() => {
    //console.log(hideall);
  }, [hideall]);

  return (
    <>
      <Header HideAll={[hideall, setHideAll]} />
      <Routes>
        <Route
          path="/"
          element={
            <Home Name={name} WelcomeMsg={welcome_msg} HideAll={hideall} />
          }
        />
        <Route
          path="/about"
          element={
            <About Name={name} WelcomeMsg={welcome_msg} HideAll={hideall} />
          }
        />
        <Route path="/appointment" element={<Appointment />}></Route>
        <Route path="*" element={<Header HideAll={[hideall, setHideAll]} />} />
      </Routes>
      <Footer HideAll={hideall} />
    </>
  );
}

export default App;
