import React, { useEffect } from "react";
import "./header.css";

function Header(props) {
  const [hideall, setHideAll] = props.HideAll;

  useEffect(() => {
    var btnmenu = document.getElementsByClassName("mobile-links-container")[0]
      .childNodes[0];
    btnmenu.addEventListener("click", () => {
      var menu = document.getElementsByClassName("mobile-links")[0];
      menu.className = "mobile-links show";
      setHideAll("none");
    });

    var btnClose = document.getElementsByClassName("mobile-links-header")[0]
      .childNodes[0];

    btnClose.addEventListener("click", () => {
      var menu = document.getElementsByClassName("mobile-links")[0];
      menu.className = "mobile-links hidden";
      setHideAll("flex");
    });
  }, []);

  const navigate = (url) => {
    if (window.location.pathname != url) {
      window.location = url;
    }
  };
  return (
    <>
      <div className="header-container">
        <div className="header-image-container">
          <img
            src="/images/fastlane-logo-text.svg"
            onClick={() => {
              navigate("/");
            }}
          />
        </div>
        <div className="link-container">
          <div className="link">
            <i className="fa-solid fa-wrench"></i>
            <span>Service</span>
          </div>

          <div
            className="link"
            onClick={() => {
              navigate("/about");
            }}
          >
            <i className="fa-solid fa-info"></i>
            <span>About</span>
          </div>
        </div>
        <div className="mobile-links-container">
          <i className="fa-solid fa-bars"></i>
          <div className="mobile-links hidden">
            <div className="mobile-links-header">
              <i className="fa-solid fa-x"></i>
            </div>
            <div className="mobile-link">
              <i className="fa-solid fa-wrench"></i>
              <span>Service</span>
            </div>

            <div
              className="mobile-link"
              onClick={() => {
                navigate("/about");
              }}
            >
              <i className="fa-solid fa-info"></i>
              <span>About</span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
