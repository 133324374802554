import "./footer.css";

function Footer(props) {
  const { title, iconClass, backImage, HideAll } = props;
  return (
    <>
      <div className="footer-container" style={{ display: HideAll }}>
        <div className="footer-social">
          <a href="#">
            <i className="fa-brands fa-facebook"></i>
          </a>
          <a href="#">
            <i className="fa-brands fa-instagram"></i>
          </a>
          <a href="#">
            <i className="fa-brands fa-tiktok"></i>
          </a>
        </div>
        <div className="footer-text">Caguas, PR</div>
      </div>
    </>
  );
}

export default Footer;
