import "./about.css";
import Card from "./card";

import Calendar from "color-calendar";
import "color-calendar/dist/css/theme-basic.css";
import "color-calendar/dist/css/theme-glass.css";
import { useEffect } from "react";

function About(props) {
  const name = props.Name;
  const welcome_msg = props.WelcomeMsg;
  const { HideAll } = props;
  return (
    <>
      <div className="body-container" style={{ display: HideAll }}>
        <div className="body-video">
          <div className="body-video-cover"></div>
          <h2>{welcome_msg}</h2>
          <p>
            We’re committed to providing the best service possible, and our
            technicians are some of the best in the industry. We take the time
            to get to know our customers and learn their unique needs, so we can
            provide the best service available. This allows us to fix your car
            the right way the first time, saving you time and money.
          </p>
          <button type="button" name="callMe">
            Call Me!
            <i className="fa-solid fa-phone-flip"></i>
          </button>
        </div>
        <div className="cards-container">
          <Card
            title="Frenos"
            iconClass="fa-solid fa-circle-stop"
            backImage="https://images.unsplash.com/photo-1613214150384-14921ff659b2?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
          />
          <Card
            title="Tune-up"
            iconClass="fa-solid fa-wrench"
            backImage="https://images.unsplash.com/photo-1619642751034-765dfdf7c58e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1374&q=80"
          />

          <Card
            title="Diagnostico Computarizado"
            iconClass="fa-solid fa-laptop-code"
            backImage="https://www.cnet.com/a/img/resize/a551cc39f301d59ce6588779acfe4b912e3a7b3b/2020/08/12/a796075f-995c-490a-be13-437ed72286ba/innova-carscan-pro-model-5610-ogi.jpg?auto=webp"
          />
          <Card
            title="Tren Delantero"
            iconClass="fa-solid fa-screwdriver"
            backImage="https://mundicoche.com/wp-content/uploads/2021/03/Sistema-del-tren-delantero-del-coche.jpg"
          />
          <Card
            title="Mecanica Liviana"
            iconClass="fa-solid fa-toolbox"
            backImage="https://images.unsplash.com/photo-1637640125496-31852f042a60?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80"
          />
          <Card
            title="Mas Servicios"
            iconClass="fa-solid fa-clipboard-list"
            backImage="https://images.unsplash.com/photo-1487754180451-c456f719a1fc?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=80"
          />
        </div>
      </div>
    </>
  );
}

export default About;
